<script setup lang="ts">
// @ts-ignore
// import { DateInstance } from "@/assets/js/dateHelper";
// import { getSessionStorageReac } from "@/assets/js/helpers";
import { $t } from "@/i18n";
import AdvantageIcons1 from "@/assets/image/advantageIcons/http.svg";
import AdvantageIcons2 from "@/assets/image/advantageIcons/integration.svg";
import AdvantageIcons3 from "@/assets/image/advantageIcons/time.svg";
import AdvantageIcons4 from "@/assets/image/advantageIcons/interface.svg";

type AdvantageItem = {
  id: string;
  title: string;
  image: string;
  description: string;
};

const title: Ref<string> = ref("Prednosti eFiskalka");
const advantageItems: AdvantageItem[] = [
  {
    id: "1",
    title: "HTTP API za izdavanje računa",
    image: AdvantageIcons1,
    description:
      "Sa našim softverom za e-fiskalizaciju, možete automatizovati procese izveštavanja o porezima, što vam štedi vreme i trud. Softver će komunicirati sa Poreskom upravom i generisati račune, što vam pomaže da ostanete u toku sa svojim poreskim obavezama.",
  },
  {
    id: "2",
    title: "Laka integracija",
    image: AdvantageIcons2,
    description:
      "Svaki biznis je drugačiji, zbog čega nudimo prilagođene APIje koji se mogu integrisati u jako veliki broj softvera. Sa našim softverom, možete lako generisati fiskalne račune koji su prilagođeni specifičnim potrebama vašeg biznisa.",
  },
  {
    id: "3",
    title: "Realno vreme praćenja",
    image: AdvantageIcons3,
    description:
      "Naš softver za e-fiskalizaciju nudi praćenje u realnom vremenu, što vam daje mogućnost da pratite svoje poslovne transakcije i poresku regulativu u realnom vremenu. To vam omogućava da prepoznate potencijalne probleme i da ih rešite pre nego što postanu problem",
  },
  {
    id: "4",
    title: "Korisnički prijateljski interfejs",
    image: AdvantageIcons4,
    description:
      "Razumemo da nije svako stručnjak za poreze, zbog čega smo dizajnirali naš softver za e-fiskalizaciju sa korisnički prijateljskim interfejsom. Naše rešenje je intuitivno i lako za korišćenje, što vam omogućava da lako upravljate svojim porezima.",
  },
];
</script>

<template lang="pug">
.advantages-wrapper 
  .title
    h1 {{title}}
  .items 
    .item(v-for="(item, index) in advantageItems" :key="index")
      .item-image
        img(:src="item.image")
      .advantages-title {{ item.title }}
      .advantages-description {{ item.description }}
  
</template>

<style lang="scss" scoped>
.advantages-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px 150px;
    background-image: url("../../assets/image/adventagescircle.png");
    background-repeat: no-repeat;
    .item {
      width: 557px;
      height: 524px;
      background: #ffffff;
      box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border-top: 7px solid #28b498;
      margin: 35px 35px 35px 35px;

      .item-image {
        margin: 30px 0 70px 0;
        img {
          width: 82px;
          height: 70px;
          height: auto;
          margin-bottom: 10px;
        }
      }

      .advantages-title {
        margin: 0 0 34px 0;
      }

      .advantages-description {
        margin: 0 0 60px 0;
      }
    }
  }
}
@media (max-width: 800px) {
  .advantages-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .items {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 1px 1px;
      .item {
        width: 311px;
        height: 433px;
        background: #ffffff;
        box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border-top: 7px solid #28b498;
        margin: 35px 35px 35px 35px;

        .item-image {
          margin: 10px 0 10px 0;
          img {
            width: 47px;
            height: 53px;
            height: auto;
            margin-bottom: 10px;
          }
        }

        .advantages-title {
          margin: 0 0 24px 0;
        }

        .advantages-description {
          margin: 0 0 30px 0;
        }
      }
    }
  }
}
</style>
